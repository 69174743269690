import React from "react";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = ({
  editScreen,
  GridFlag,
  TenantFilterCell,
  BooleanValuesFilterCell,
}) => {
  const dataColumns = [
    {
      field: "ossiTcFormId",
      title: "Form Id",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className="edit-link"
          onClick={() => {
            editScreen(props.dataItem);
          }}
        >
          {props.dataItem.ossiTcFormId}
        </td>
      ),
    },
    {
      field: "row_no",
      title: "Row No",
      editable: false,
      show: false,
      width: "250px",
      minResizableWidth: 150,
      excludeFromFilter: true,
    },
    {
      field: "tenantId",
      title: "Tenant Id",
      editable: false,
      show: false,
      width: "250px",
      minResizableWidth: 150,
      excludeFromFilter: true,
    },
    {
      field: "tenantName",
      title: "Tenant",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 150,
      filterCell: (props) =>
        TenantFilterCell({
          ...props,
          filterType: "dropdown",
        }),
    },
    {
      field: "ucOssiApplId",
      title: "App Id",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "ucOssiAppVer",
      title: "App Version",
      editable: false,
      show: true,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: "ucOssiSrtseq",
      title: "Sort Sequence",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: "ucOssiTtl",
      title: "Title",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: "ucOssiType",
      title: "Type",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "ucOssiInfo",
      title: "Info",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "ucOssiHeight",
      title: "Height",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "ucOssiInfoSqlFlg",
      title: "Info Sql Flg",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      cell: GridFlag,
      filterCell: BooleanValuesFilterCell,
    },
    {
      field: "ucOssiDisableFlg",
      title: "Disable",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      cell: GridFlag,
      filterCell: BooleanValuesFilterCell,
    },
  ];

  return dataColumns;
};
export default DataColumns;
