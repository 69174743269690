import { DataColumns } from '../DataColumns/DataColumns';
import GridFlag from '../../../../Core/Components/GridFlag/GridFlag';
import {
  TenantFilterCell,
  BooleanValuesFilterCell,
} from '../../../../Core/Components/Filter/FilterElements';

/**
 * Generates a list of columns for a grid.
 * @param {object} options - The options for the grid.
 * @returns {Array} - The list of grid columns.
 */
export const GridColumnsList = async ({ editScreen }) => {
  return DataColumns({
    editScreen,
    GridFlag,
    TenantFilterCell,
    BooleanValuesFilterCell,
  });
};

export default GridColumnsList;
